@import './styles/_variables';

@mixin purple-matter {
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-family: 'Proxima Nova';
  font-style: normal;
  line-height: normal;

  font-size: 0.875rem;

  border-radius: 50px;
  border: 1px solid var(--Purple-Matter, #986ecc);
  background: radial-gradient(
    64.79% 132.86% at 50% 0%,
    #d681f3 0%,
    #3b85c9 100%
  );
  box-shadow: 0px 0px 15px 0px rgba(230, 236, 238, 0.7);
  backdrop-filter: blur(7.5px);
  position: relative; // Needed for pseudo-element positioning
  display: flex;

  cursor: pointer;

  // Inner highlight effect
  &::before {
    content: '';
    position: absolute;
    top: 3px; // Small offset to simulate inner highlight
    left: 3px;
    right: 3px;
    bottom: 3px;
    border-radius: 100px; // More rounded than the outer border
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0) 41%
    );
    z-index: 0;
  }

  // Ensure text or icons above pseudo-element
  > * {
    position: relative;
    z-index: 1;
  }
}

@mixin blurry {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(12.5px);
}

body,
html,
#root {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

#root {
  flex: 1;
  display: flex;
  flex-direction: column;
}

body {
  font-family: 'Proxima Nova', sans-serif;
  color: $blue-2;
}

h1 {
  font-family: 'GeoSlab703 Md BT';
  text-transform: uppercase;
  color: $blue-2;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  font-size: 1.5rem;
}

h2 {
  color: $blue-1;
}

.blurred {
  @include blurry;
}

label {
  line-height: 0.914rem;
}

button {
  @include purple-matter;

  justify-content: center;
  align-items: center;
  padding: 0.625rem 2.5rem;
  text-transform: uppercase;
  font-weight: 700;

  &:disabled {
    opacity: 0.4;
    backdrop-filter: blur(7.5px);
  }

  &.white-btn {
    background-color: white !important;
    color: $blue-2;
    border-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    background: linear-gradient(
      180deg,
      rgba(59, 133, 201, 0.2) 0%,
      rgba(59, 133, 201, 0) 42.11%
    );
  }

  &.naked {
    background: none;
    border: none;
    box-shadow: none;
    padding: 0;
  }

  &.unselected {
    &::before {
      background: none;
    }
  }
}

.checked {
  @include purple-matter;
  padding-left: 1rem;
  font-weight: 700;
}

input,
select {
  background: rgba(255, 255, 255, 0.5); // White background with 50% opacity
  backdrop-filter: blur(10px); // Blur effect
}

.blue-text {
  color: $blue-2;
}

.bg-purple {
  background-color: $purple-2;
}

.bg-blue {
  background-color: $blue-2;
}

button.blue-text {
  color: $blue-2;
}

.border-purple {
  border-color: $purple-2;
}

.deep-purple {
  color: $purple-4;
}

.rounded-lg {
  border-radius: 1.125rem;
}

.backdrop {
  border-radius: inherit;
  background: inherit;
  backdrop-filter: inherit;
}

.option-container {
  &.multiline {
    border-radius: 1.125rem;

    // for border radius of the pseudo-element
    &::before {
      border-radius: 1.125rem;
    }
  }

  // Ensures the box grows as needed
  overflow: hidden;
  white-space: normal;
}

.back-link {
  color: $purple-4;
}

// responsive adjustments
@media (min-width: 1024px) {
  .backdrop {
    border-radius: 54px;
    @include blurry;
  }

  .rounded-lg {
    border-radius: 2rem;
  }
}
